/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Suspense } from "react";

import { observer, inject } from "mobx-react";
import { Layout, Menu, Button, Col, Avatar, Dropdown, Row, Badge } from "antd";
import {
  DownOutlined,
  HeartFilled,
  MenuOutlined,
  DownSquareOutlined,
  BankOutlined,
  UnorderedListOutlined,
  AppstoreOutlined,
  LineChartOutlined,
  UserOutlined,
  LogoutOutlined,
  NotificationOutlined,
  FontColorsOutlined,
  GlobalOutlined,
  UserSwitchOutlined,
  SolutionOutlined,
  ClusterOutlined,
  BookOutlined,
  HighlightOutlined,
  LockOutlined,
} from "@ant-design/icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileInvoiceDollar,
  faCogs,
  faAddressBook,
  faIdCard,
  faDollarSign,
} from "@fortawesome/free-solid-svg-icons";
import { withTranslation } from "react-i18next";

import { Switch, Route, Link, withRouter } from "react-router-dom";

import debounce from "lodash/debounce";
import i18n from "../../i18n";
import Loading from "../basic/Loading";
import routes from "./dashboardRoutes";
import getAgentInstance from "../../helpers/superagent";

import "./dashboard.css";

const superagent = getAgentInstance();
const { Header, Content, Footer, Sider } = Layout;

@inject("userStore", "tokenStore", "sectionsStore")
@observer
class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.initialState = () => ({
      collapsed: false,
      windowsWidth: 0,
      notSeen: 0,
    });
    this.state = this.initialState();

    this.routeClicked = (history, to) => {
      history.push(to);
    };
    this.toggleSidebar = () => {
      this.setState((prevState) => ({
        collapsed: !prevState.collapsed,
      }));
    };
    this.logout = () => {
      Promise.all([
        this.props.userStore.clear(),
        this.props.tokenStore.clear(),
        this.props.sectionsStore.clear(),
      ]).then(() => {
        caches.open("v1").then(() => {
          window.location.reload();
        });
      });
    };

    this.fetchCounter = () => {};

    this.resetNotSeen = () => {
      this.setState({ notSeen: 0 });
    };

    this.updateDimensions = debounce(() => {
      this.setState({ windowsWidth: window.innerWidth });
      const { windowsWidth } = this.state;
      if (windowsWidth < 992) {
        this.setState({ collapsed: true });
      } else {
        this.setState({ collapsed: false });
      }
    }, 300);
  }

  componentDidMount() {
    this.fetchCounter();
    window.addEventListener("resize", this.updateDimensions);
    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  render() {
    const { collapsed, windowsWidth, notSeen } = this.state;
    const { location } = this.props;

    return (
      <Layout style={{ minHeight: "100vh" }}>
        <Sider
          theme="dark"
          style={{
            height: "100vh",
            left: i18n.language === "en" ? 0 : null,
            right: i18n.language !== "en" ? 0 : null,
            position: "fixed",
            overflow: "auto",
            background: "rgb(37, 34, 34)",
          }}
          width={collapsed ? 0 : 245}
        >
          <div
            style={{
              position: "relative",
              height: 64,
              background: "rgb(20, 19, 19)",
            }}
          >
            <span
              style={{
                color: "#fff",
                fontSize: 14,
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              {i18n.t("SUE Dashboard")}
            </span>
          </div>

          <Menu
            theme="dark"
            mode="inline"
            selectedKeys={[location.pathname]}
            style={{ background: "rgb(37, 34, 34)", marginTop: 10 }}
          >
            <Menu.Item key="/">
              <HighlightOutlined />
              <span>{i18n.t("Theses")}</span>
              <Link to="/" />
            </Menu.Item>

            <Menu.Item key="/collage">
              <AppstoreOutlined />
              <span>{i18n.t("Collage")}</span>
              <Link to="/collage" />
            </Menu.Item>

            <Menu.Item key="/university">
              <BankOutlined />
              <span>{i18n.t("University")}</span>
              <Link to="/university" />
            </Menu.Item>

            <Menu.Item key="/department">
              <ClusterOutlined />
              <span>{i18n.t("Department")}</span>
              <Link to="/department" />
            </Menu.Item>

            <Menu.Item key="/degree">
              <BookOutlined />
              <span>{i18n.t("Degree")}</span>
              <Link to="/degree" />
            </Menu.Item>

            <Menu.Item key="/student">
              <SolutionOutlined />
              <span>{i18n.t("Student")}</span>
              <Link to="/student" />
            </Menu.Item>

            <Menu.Item key="/supervisor">
              <UserSwitchOutlined />
              <span>{i18n.t("Supervisor")}</span>
              <Link to="/supervisor" />
            </Menu.Item>
            <Menu.Item key="/city">
              <GlobalOutlined />
              <span>{i18n.t("City")}</span>
              <Link to="/city" />
            </Menu.Item>

            <Menu.Item key="/keywords">
              <FontColorsOutlined />
              <span>{i18n.t("Keywords")}</span>
              <Link to="/keywords" />
            </Menu.Item>

            <Menu.Item key="/user">
              <LockOutlined />
              <span>{i18n.t("Users")}</span>
              <Link to="/user" />
            </Menu.Item>

          </Menu>
        </Sider>
        <Layout
          className="site-layout"
          style={{
            transition: "all 0.2s",
            marginLeft: i18n.language === "en" && collapsed === false ? 240 : 0,
            marginRight:
              i18n.language !== "en" && collapsed === false ? 240 : 0,
          }}
        >
          <Header
            className="site-layout-background"
            style={{ padding: 0, display: "inherit" }}
          >
            <Col
              span={12}
              style={{
                textAlign: "start",

                paddingLeft: i18n.language === "en" ? 10 : undefined,
                paddingRight: i18n.language !== "en" ? 10 : undefined,
              }}
            >
              <Button
                type="link"
                icon={
                  <MenuOutlined style={{ fontSize: 15, color: "#1f2532" }} />
                }
                onClick={this.toggleSidebar}
              />
            </Col>

            <Col
              span={12}
              style={{
                textAlign: "end",
                paddingRight: 15,
                display: !collapsed && windowsWidth < 420 ? "none" : undefined,
              }}
            >
              <Row gutter={10}>
                <Col span={22}></Col>
                <Col span={2}>
                  <Dropdown
                    arrow
                    placement="bottomRight"
                    trigger={["click"]}
                    overlay={
                      <>
                        <Menu style={{ width: "160px" }}>
                          <Menu.Item key="1" icon={<LogoutOutlined />}>
                            <a href="# " onClick={this.logout}>
                              {i18n.t("Logout")}
                            </a>
                          </Menu.Item>
                        </Menu>
                      </>
                    }
                  >
                    <Avatar
                      style={{
                        cursor: "pointer",
                        marginLeft: i18n.language !== "en" ? "10px" : undefined,
                      }}
                      shape="square"
                      icon={<UserOutlined />}
                    />
                  </Dropdown>
                </Col>
              </Row>
            </Col>
          </Header>

          <Content style={{ margin: "16px 16px", marginBottom: 0 }}>
            <div
              className="site-layout-background"
              style={{ padding: 24, minHeight: 500 }}
            >
              <Suspense fallback={<Loading />}>
                <Switch>
                  {routes.map((route) => (
                    <Route
                      exact={route.exact}
                      key={route.key}
                      path={`${route.path}`}
                    >
                      <route.component />
                    </Route>
                  ))}
                </Switch>
              </Suspense>
            </div>
          </Content>
        </Layout>
      </Layout>
    );
  }
}

export default withTranslation()(
  withRouter((props) => <Dashboard {...props} />)
);

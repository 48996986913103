import React, { Suspense } from 'react';
import { ConfigProvider } from 'antd';
import { useTranslation } from 'react-i18next';

import Dashboard from './components/layout/dashboard';
import AuthGuard from './components/AuthGuard';

import './assets/styles/global.css';

const rtlLocales = ['ar', 'ku'];

function App() {
  const { i18n } = useTranslation();
  return (
    <Suspense fallback="loading...">
     
      <div className="main-wrapper" lang={i18n.language}>
        <ConfigProvider direction={rtlLocales.indexOf(i18n.language) > -1 ? 'rtl' : 'ltr'}>
          <AuthGuard>
            <Dashboard />
          </AuthGuard>
        </ConfigProvider>
      </div>
    </Suspense>
  );
}

export default App;

import { lazy } from "react";

const options = [
  {
    key: Math.random(),
    path: "/",
    component: lazy(() => import("../thesis/List")),
    exact: true,
  },
  {
    key: Math.random(),
    path: "/collage",
    component: lazy(() => import("../collage/List")),
    exact: true,
  },
  {
    key: Math.random(),
    path: "/city",
    component: lazy(() => import("../city/List")),
    exact: true,
  },
  {
    key: Math.random(),
    path: "/university",
    component: lazy(() => import("../university/List")),
    exact: true,
  },
  {
    key: Math.random(),
    path: "/degree",
    component: lazy(() => import("../degree/List")),
    exact: true,
  },
  {
    key: Math.random(),
    path: "/student",
    component: lazy(() => import("../student/List")),
    exact: true,
  },
  {
    key: Math.random(),
    path: "/supervisor",
    component: lazy(() => import("../supervisor/List")),
    exact: true,
  },
  {
    key: Math.random(),
    path: "/department",
    component: lazy(() => import("../department/List")),
    exact: true,
  },
  {
    key: Math.random(),
    path: "/user",
    component: lazy(() => import("../user/List")),
    exact: true,
  },
  {
    key: Math.random(),
    path: "/keywords",
    component: lazy(() => import("../keywords/List")),
    exact: true,
  },
];

export default options;
